.WrapperPhoto{
    padding: 100px 20px 20px 20px;
    position: relative;
    max-width: 1200px;
    margin: auto;
    width: 100%;
    .buttons{
        display: none;
        position: absolute;
        top: 20px;
        right: 20px;
        a{
            border-radius: 50%;
            color: white;
            width: 60px;
            height: 60px;
            background: $baseColor;
            font-size: 1.6em;
            display: flex;
            justify-content: center;
            align-items: center;
            float: left;
            margin-left: 10px;
            text-decoration: none;
            &:hover{
                background: darken($baseColor, 10);
            }
        }
    }
    .camera{
        width: 100%;
        position: relative;
    }
    video, #my_camera{
        max-width: 100% !important;
        width: 100% !important;
        height: auto !important;
    }
    #result{
        width: 100%;
        height: auto;
    }
    .overlay{
        background-color:rgba(0,0,0,0.5);
        height:98.5%;
        width:100%;
        position:absolute;
        cursor:pointer;
        .start{
            position:absolute;
            z-index:99999;
            left:50%;
            top:50%;
            transform:translate(-50%, -50%);
            color:white;
            text-transform:uppercase;
            font-family: 'RockwellStd-Bold';
            font-size:1.5em;
            span{
                display: block;
                width: 250px;
                height: 250px;
                margin: auto;
                border: 6px solid white;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 4em;
                margin-bottom: 20px;
                padding-top: 15px;
                .fa-camera-retro{
                    position: relative;
                    top: 0px;
                }
            }
        }
    }
}


.print-info{
    display: none;
    text-align: center;
    padding: 20px;
    background-color: white;

    h2{
        font-size: 1.5em;
        letter-spacing: 4px;
    }
    img{
        max-width: 100px;
    }
}
.mail-popup, .print-popup{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display:none;
    position:fixed;
    z-index:99999;
    background: rgba(0,0,0,0.5);
    form{
        position: absolute;
        min-width: 450px;
        text-align: center;
        left: 50%;
        top: 50%;
        z-index:99999;
        background: white;
        transform: translate(-50%, -50%);
        .close {
            position: absolute;
            right: 15px;
            top: 15px;
            width: 20px;
            height: 20px;
            opacity: 0.3;
            &:hover {
                opacity: 1;
            }
            &:before, &:after {
                position: absolute;
                left: 15px;
                content: ' ';
                height: 20px;
                width: 2px;
                background-color: #333;
            }
            &:before {
              transform: rotate(45deg);
            }
            &:after {
              transform: rotate(-45deg);
            }
        }
        p{
            font-family: 'RockwellStd-Bold';
            text-transform: uppercase;
            font-size: 1.2em;
        }
        .wrapContent{
            padding: 40px 20px 20px 20px;
            #inputFields{
                max-height: 150px;
                overflow: scroll;
            }
            .inputField, .inputField2{
                margin-bottom: 10px;
                position: relative;
            }
            input{
                appearance: none;
                border: none;
                border-radius: 0px;
                background: white;
                border-bottom: 1px solid black;
                width: 100%;
                padding: 5px 35px 5px 30px;
                position: relative;
                outline: none;
                font-size: 0.9em;
                color: black;
            }
            .envelopeIcon{
                background-image: url(/images/email.png);
                background-position: left center;
                background-repeat: no-repeat;
                background-size: contain;
                width: 20px;
                height: 20px;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                display: block;
                position: absolute;
            }
            .textIcon{
                background-image: url(/images/text.png);
                background-position: left center;
                background-repeat: no-repeat;
                background-size: contain;
                width: 20px;
                height: 20px;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                display: block;
                position: absolute;
            }
            .addButton{
                position: absolute;
                right: 0;
                top: 0;
                border: none;
                border-radius: 50%;
                background: $baseColor;
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                font-size: 0.8em;
                outline: none;
            }
        }
        .wrapButtons{
            padding: 40px 20px 20px 20px;
            background: $baseColor;
            position: relative;
            &:after{
                position: absolute;
                left: 50%;
                top: -1px;
                transform: translateX(-50%);
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 20px 20px 0 20px;
                border-color: white transparent transparent transparent;
            }
            input{
                background: white;
                color: $baseColor;
                border-radius: 50px;
                padding: 15px 30px 10px 30px;
                border: 2px solid $baseColor;
                &:hover{
                    background: transparent;
                    border: 2px solid white;
                    color: white;
                }
            }
        }
    }
}

.inputField2{
    position: relative; 
}