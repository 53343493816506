nav{
    background: $baseColor;
    img{
        height: auto;
        width: 250px;
        position: absolute;
        top: -2px;
        margin-left: 10px;
    }
    .wrapperItems{
        float: right;
        width: calc(100% - 280px);
        margin-right: 20px;
        h1{
            padding: 40px 0 20px 10px;
            color: white;
            font-size: 2em;
            font-family: 'RockwellStd-Bold';
            float: left;
            margin: 0;
            width: calc(100% - 90px);

        }
        a{
            color: white;
            text-decoration: none;
        }
        a.photo{
            margin: 20px 0;
            float: right;
            width: 70px;
            height: 70px;
            font-size: 2em;
            display: flex;
            justify-content: center;
            align-items: center;
            background: white;
            color: $baseColor;
            border-radius: 50%;
        }
    }
}

@media screen and(max-width: 1250px){
    nav{
        img{
            width: 200px;
        }
        .wrapperItems{
            float: right;
            width: calc(100% - 230px);
            margin-right: 20px;
            h1{
                padding: 35px 0 20px 10px;
                font-size: 1.6em;
                width: calc(100% - 80px);

            }
            a{
                color: white;
                text-decoration: none;
            }
            a.photo{
                width: 60px;
                height: 60px;
                font-size: 1.6em;
            }
        }
    }
}

@media screen and(max-width: 700px){
    nav{
        position: relative;
        img{
            height: auto;
            width: 200px;
            position: static;
            top: -2px;
        }
        .wrapperItems{
            float: none;
            width: 90%;
            margin: auto;
            h1{
                padding: 20px 0 20px 10px;
                float: none;
                margin: 0;
                width: 100%;
                text-align: center;
            }
            a.photo{
                float: none;
                width: 70px;
                height: 70px;
                position: absolute;
                right: 20px;
                top: 20px;
                font-size: 2em;
                display: flex;
                justify-content: center;
                align-items: center;
                background: white;
                color: $baseColor;
                border-radius: 50%;
            }
        }
    }
}
