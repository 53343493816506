@font-face {
    font-family: 'RockwellStd-Bold';
    src: url('/fonts/RockwellStd-Bold.eot');
    src: url('/fonts/RockwellStd-Bold.eot?#iefix') format('embedded-opentype'),
         url('/fonts/RockwellStd-Bold.woff') format('woff'),
         url('/fonts/RockwellStd-Bold.ttf') format('truetype'),
         url('/fonts/RockwellStd-Bold.svg#RockwellStd-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'ITCAvantGardeStd-Bk';
    src: url('/fonts/ITCAvantGardeStd-Bk.eot');
    src: url('/fonts/ITCAvantGardeStd-Bk.eot?#iefix') format('embedded-opentype'),
         url('/fonts/ITCAvantGardeStd-Bk.woff') format('woff'),
         url('/fonts/ITCAvantGardeStd-Bk.ttf') format('truetype'),
         url('/fonts/ITCAvantGardeStd-Bk.svg#ITCAvantGardeStd-Bk') format('svg');
    font-weight: normal;
    font-style: normal;
}
