.presentation{
    margin: 80px 20px 20px 20px;
    .goTo{
        display: block;
        opacity: 0.6;
        margin-bottom: 20px;
        text-decoration: none;
        color: black;
        i{
            margin-right: 5px;
        }
        &:hover{
            color: $baseColor;
        }
    }
    .left{
        float: left;
        width: calc(100% - 380px);
        position: relative;
        img{
            width: 100%;
        }
        p{
            text-align: center;
            margin-top: 10px;
        }
        .buttons{
            position: absolute;
            bottom: 50px;
            color: white;
            font-size: 1.5em;
            padding: 20px;
            width: 100%;
            a.prev-btn{
                float: left;
                color: white;
                opacity: 0.7;
                &:hover{
                    opacity: 1;
                }
            }
            a.next-btn{
                float: right;
                color: white;
                opacity: 0.6;
                &:hover{
                    opacity: 1;
                }
            }
        }
    }
    .right{
        width: 350px;
        float: right;
        a{
            text-decoration: none;
            display: block;
            color: black;
            &:hover{
                transform: scale(1.02);
            }
        }
        span{
            display: block;
            margin-top: 10px;
        }
    }
}

@media screen and(max-width: 1200px){
    .presentation{
        margin: 60px 20px 20px 20px;
        .left{
            width: calc(100% - 280px);
        }
        .right{
            width: 250px;
        }
    }
}

@media screen and(max-width: 1000px){
    .presentation{
        .left{
            float: left;
            width: calc(100% - 230px);
        }
        .right{
            width: 200px;
            float: right;
        }
    }
}

@media screen and(max-width: 800px){
    .presentation{
        .left{
            float: none;
            width: 100%;
        }
        .right{
            width: 300px;
            float: right;
        }
    }
}

@media screen and(max-width: 500px){
    .presentation{
        .left{
            float: none;
            width: 100%;
        }
        .right{
            width: 200px;
            float: right;
        }
    }
}
