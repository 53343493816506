#wrapperLogin{
    min-height: 600px;
    width: 100%;
    .left{
        width: 50%;
        height: 100%;
        float: left;
        background-image: url(/images/login_aartsenfruit.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        .layer{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba($baseColor,0.9);
        }
        .content{
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 100;
            color: white;
            padding: 40px;
            h1{
                text-transform: uppercase;
                font-size: 4em;
                margin: 0;
                font-family: 'RockwellStd-Bold';
                line-height: 1;
            }
            p{
                margin: 0;
                font-size: 1.2em;
                line-height: 1.3;
            }
        }
    }
    .right{
        width: 50%;
        height: 100%;
        float: right;
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        img{
            width: 250px;
            margin: 0 auto 60px auto;
            display: block;
        }
        fieldset{
            display: block;
            width: 100%;
            position: relative;
            margin: 20px 0;
            min-width: 500px;
            input#password, input#email{
                appearance: none;
                border: none;
                border-radius: 0px;
                background: white;
                border-bottom: 1px solid black;
                width: 100%;
                padding: 5px 0 5px 30px;
                position: relative;
                outline: none;
            }
            .passwordIcon{
                background-image: url(/images/key.png);
                background-position: left center;
                background-repeat: no-repeat;
                background-size: contain;
                width: 20px;
                height: 20px;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                display: block;
                position: absolute;
            }
            .emailIcon{
                background-image: url(/images/user.png);
                background-position: left center;
                background-repeat: no-repeat;
                background-size: contain;
                width: 20px;
                height: 20px;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                display: block;
                position: absolute;
            }
            .submitBttn{
                float: right;
                border-radius: 50px;
                border: 1px solid $baseColor;
                background: $baseColor;
                padding: 10px 30px 8px 30px;
                font-size: 1em;
                cursor: pointer;
                transition: 0.3s all;
                outline: none;
                color: white;
                &:hover{
                    background: transparent;
                    color: $baseColor;
                }
            }
        }
        .checkboxWrap {
            display: block;
            position: relative;
            padding-left: 25px;
            margin-bottom: 10px;
            cursor: pointer;
            font-size: 1em;
            user-select: none;
            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }
            .checkmark {
                position: absolute;
                top: 4px;
                left: 0;
                height: 15px;
                width: 15px;
                background-color: #eee;
            }
            input:checked ~ .checkmark {
                background-color: $baseColor;
                &:after {
                    content: "";
                    position: absolute;
                    display: none;
                    left: 6px;
                    top: 3px;
                    width: 4px;
                    height: 8px;
                    border: solid white;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                }
            }
            input:checked ~ .checkmark:after {
              display: block;
            }
        }
        a.btn{
            position: absolute;
            right: 10px;
            bottom: 10px;
            color: black;
            font-size: 1em;
            &:hover{
                color: $baseColor;
            }
        }
    }
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-animation: autofill 0s forwards;
    animation: autofill 0s forwards;
}

@keyframes autofill {
    100% {
        background: transparent;
        color: inherit;
    }
}

@-webkit-keyframes autofill {
    100% {
        background: transparent;
        color: inherit;
    }
}

@media screen and(max-width: 1100px){
    #wrapperLogin{
        .right{
            fieldset{
                min-width: 400px;
            }
        }
    }
}

@media screen and(max-width: 900px){
    #wrapperLogin{
        height: auto !important;
        .left{
            width: 100%;
            height: 100%;
            min-height: 400px;
            float: none;
            .content{
                h1{
                    font-size: 3em;
                }
                p{
                    font-size: 1em;
                }
            }
        }
        .right{
            width: 100%;
            display: block;
            margin: auto;
            height: 100%;
            float: none;
            padding-bottom: 80px;
            fieldset{
                min-width: 100%;
            }
        }
    }
}
