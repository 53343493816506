#wrapperContent{
    .videoGrid{
        list-style: none;
        display: block;
        margin: 80px 20px 20px 20px;
        li{
            float: left;
            width: calc(33.33% - 2em);
            margin: 1em;
            position: relative;
            cursor: pointer;
            &.fist{
                background: $baseColor;
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                &.active{
                    background: transparent;
                    box-shadow:inset 0px 0px 0px 2px $baseColor;
                    color: $baseColor;
                    .playbutton{
                        border: 7px solid $baseColor;
                        &:after{
                            content: "\f04d";
                            color: $baseColor;
                        }
                    }
                    p{
                        &.yesActive{
                            display: block;
                        }
                        &.noActive{
                            display: none;
                        }
                    }
                }
                .playbutton{
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    border: 7px solid white;
                    margin: auto;
                    opacity: 0.7;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &:after{
                        content: "\f04b";
                        font-size: 2.2em;
                        font-family: FontAwesome;
                        color: white;
                    }
                }
                p{
                    text-transform: uppercase;
                    margin-top: 20px;
                    &.yesActive{
                        display: none;
                    }
                }
            }
            &.active{
                .image{
                    .layer{
                        background: rgba($baseColor, 0.7);
                        .playbutton{
                            &:after{
                                content: "\f04d";
                            }
                        }
                    }
                }
            }
            .image{
                width: 100%;
                height: 300px;
                box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.1);
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                position: relative;
            }
            .layer{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: rgba(0,0,0,0.3);
                display: flex;
                justify-content: center;
                align-items: center;
                .playbutton{
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    border: 7px solid white;
                    opacity: 0.7;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &:after{
                        content: "\f04b";
                        font-size: 2.2em;
                        font-family: FontAwesome;
                        color: white;
                    }
                }
            }
            p{
                margin-bottom: 0;
                line-height: 1;
                margin-top: 10px;font-family: 'MyriadPro-Bold';
            }
        }
    }
}

@media screen and(max-width: 1200px){
    #wrapperContent{
        .videoGrid{
            margin: 40px 20px 20px 20px;
            li{
                width: calc(33.33% - 2em);
            }
        }
    }
}

@media screen and(max-width: 950px){
    #wrapperContent{
        .videoGrid{
            li{
                width: calc(50% - 2em);
            }
        }
    }
}

@media screen and(max-width: 600px){
    #wrapperContent{
        .header{
            h1{
                font-size: 1.2em;
            }
        }
        .videoGrid{
            li{
                float: none;
                width: 100%;
                margin: 1em 0;
                &.fist{
                    padding: 20px;
                }
            }
        }
    }
}



div#mlkeyboard{
    ul {
        width: 1095px !important;
        height: 285px !important;
    }

    ul li {
        width: 70px !important;
        height: 50px !important;
        line-height: 50px !important;
        margin: 2px !important;
        font-size: 19px !important;
    }

    #mlkeyboard-tab, #mlkeyboard-backspace{
        width: 100px !important;
    }

    #mlkeyboard-capslock, #mlkeyboard-return{
        width: 123px !important;
    }

    #mlkeyboard-left-shift, #mlkeyboard-right-shift {
        width: 161px !important;
    }

    #mlkeyboard-space {
        width: 99% !important;
    }
}