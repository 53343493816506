*{
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-weight: lighter;
	height: auto;
	outline: none;
}
body{
	overflow-y:scroll;
}
.hideOnClick{
	opacity: 0;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.alert-info {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
	text-align: center;
	z-index: -1
}

html, body{
	width: 100%;
	height: 100%;
	font-size: 18px;
	margin: 0;
	padding: 0;
	font-family: 'ITCAvantGardeStd-Bk';
	background-color: transparent !important;
	color: black;
}

img{
	max-width: 100%;
  	height: auto;
	border: 0;
}
a{
	text-decoration: none;
	outline: none;
	transition: all 0.5s;
  	color: inherit;
}
.containerBig{
	width: 100%;
	margin: 0 auto;
	position: relative;
	z-index: 10;
	max-width: 1100px;
}
.containersmall{
	width: 100%;
	margin: 0 auto;
	position: relative;
	z-index: 10;
	max-width: 900px;
}
p {
  	margin-bottom: 1em;
}

.clearing{
	clear: both;
	display: block;
}

.alert-danger ul {
	list-style: none !important;
}

@media screen and(max-width: 1100px){
  .containerBig{
  	width: 95%;
  }
}

@media screen and(max-width: 900px){
	.containersmall{
		width: 95%;
	}
}
